import React, { useEffect } from 'react'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

import generateFooterAndHeader from '../../../utils/generateFooterAndHeader'
import { ProjectList } from '../../../utils/constants'
import { PROJECT_NAME, DIGITAL_STORE_URL } from '../../../env'
import { connect } from 'react-redux'

function HF({
  intl: { formatMessage },
  onLanguageChange,
  userRole,
  products,
  kitsData,
  selectedLanguage,
  isMakerPlan,
  loaded,
}) {
  const history = useHistory()
  window._push = history.push

  useEffect(() => {
    if (window.header?.setHistory) {
      window.header.setHistory(history)
    }
    generateFooterAndHeader({ onLanguageChange, selectedLanguage }, { formatMessage, products, kitsData }, userRole)
    const generateButtons = async () => {
      if (PROJECT_NAME === ProjectList.SENSORKIT) {
        document.getElementById('header').classList.add('with-buttons')
        window.header.renderMenuButtons([
          {
            url: 'https://store.arduino.cc/sensor-kit-bundle',
            blank: true,
            label: 'GET YOUR KIT',
          },
        ])
      }

      if (PROJECT_NAME === ProjectList.OPLA) {
        if (!loaded) return
        if (!isMakerPlan) {
          document.getElementById('header').classList.add('with-buttons')
          document.getElementById('root').classList.add('with-buttons')
          window.header.renderMenuButtons([
            {
              url: 'https://store-usa.arduino.cc/products/arduino-opla-iot-kit',
              blank: true,
              label: 'GET YOUR KIT',
            },
            {
              url: `${DIGITAL_STORE_URL}/plans`,
              blank: true,
              label: 'ACTIVATE MAKER TRIAL',
              main: true,
            },
          ])
        }
      }

      if (PROJECT_NAME === ProjectList.MAKEYOURUNO) {
        document.getElementById('header').classList.add('with-buttons')
        window.header.renderMenuButtons([
          {
            url: 'https://store.arduino.cc/products/make-your-uno-kit',
            blank: true,
            label: 'GET YOUR KIT',
          },
        ])
      }
    }
    generateButtons()
  }, [loaded])

  return <React.Fragment />
}

HF.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }),
  onLanguageChange: PropTypes.func,
  userRole: PropTypes.string,
  role: PropTypes.string,
  products: PropTypes.object,
  kitsData: PropTypes.object,
  selectedLanguage: PropTypes.string,
  isMakerPlan: PropTypes.bool,
  loaded: PropTypes.bool,
  isMinor: PropTypes.bool,
}

const mapStateToProps = (state) => {
  return {
    isMinor: state.root.profile.data.isMinor,
  }
}

export default injectIntl(connect(mapStateToProps)(HF))
