import { PROJECT_NAME } from '../env'

const ProjectList = {
  CTC_GO: 'ctc-go',
  CTC101: 'ctc101',
  STARTER_KIT: 'starter-kit',
  STUDENT_KIT: 'student-kit',
  ENGINEERING_KIT: 'engineering-kit',
  CONTENTPREVIEW: 'content-preview',
  IOT_SK: 'explore-iot',
  SJ: 'science-journal',
  OPLA: 'opla',
  SENSORKIT: 'sensor-kit',
  BRACCIO: 'braccio',
  PHYSICS_LAB: 'physics-lab',
  GREENHOUSE: 'greenhouse-kit',
  MAKEYOURUNO: 'makeyouruno-kit',
}

const AppAccessible = {
  [ProjectList.CTC_GO]: 'CTCGO',
  [ProjectList.CTC101]: 'CTC101',
  [ProjectList.CONTENTPREVIEW]: 'CONTENTPREVIEW',
  [ProjectList.SJ]: 'SJ',
  [ProjectList.IOT_SK]: 'IOTSK',
  [ProjectList.STARTER_KIT]: 'AESK',
  [ProjectList.STUDENT_KIT]: 'STUDENTKIT',
  [ProjectList.ENGINEERING_KIT]: 'AEKR2',
  [ProjectList.OPLA]: 'OPLA',
  [ProjectList.SENSORKIT]: 'SENSORKIT',
  [ProjectList.BRACCIO]: 'BRACCIO',
  [ProjectList.PHYSICS_LAB]: 'PHYSICSLAB',
  [ProjectList.GREENHOUSE]: 'GREENHOUSE',
  [ProjectList.MAKEYOURUNO]: 'MAKEYOURUNO',
}

const UserRoles = {
  STUDENT: 'student',
  TEACHER: 'teacher',
  ADMIN: 'admin',
}

function hasExtendedAccess(role) {
  return role === UserRoles.TEACHER || role === UserRoles.ADMIN
}

function isShowContact(role) {
  if (
    (PROJECT_NAME === ProjectList.STUDENT_KIT && role === UserRoles.STUDENT) ||
    (PROJECT_NAME === ProjectList.STARTER_KIT && role === UserRoles.STUDENT)
  ) {
    return false
  }
  return true
}

function isZenWidgetShow(role) {
  if (
    (PROJECT_NAME === ProjectList.CTC_GO && role === UserRoles.STUDENT) ||
    (PROJECT_NAME === ProjectList.STARTER_KIT && role === UserRoles.STUDENT)
  ) {
    return false
  }
  return true
}

export const DATO_IMAGE = `
srcSet
webpSrcSet
sizes
src

width
height
aspectRatio

alt
title

bgColor

base64
`
export const isDev = process.env.FLOW_ENV === 'dev'

export const MY_CLOUD_LINK = isDev ? 'https://cloud.oniudra.cc/home/' : 'https://cloud.arduino.cc/home/'

export const CLASSROOM_DASHBOARD = `${MY_CLOUD_LINK}?kind=${AppAccessible[process.env.PROJECT]}`

export { ProjectList, UserRoles, hasExtendedAccess, AppAccessible, isShowContact, isZenWidgetShow }
